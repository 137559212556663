import restaurant from "./images/imperial.jpg";
import biserica from "./images/biserica1.jpeg";
import img_card from "./images/amelia.jpeg";
import imgheadermb from "./images/m7.png";
import imgheader from "./images/m2.png";
import imgheadermiini from "./images/h14.jpg";
import imgheadermiinimb from "./images/h14.jpg";
import logo from './images/fluture2.png'



const data = {
    introData: [{
        copilul: "Amelia",
        familia: "fam. Roșca",
        logo: logo,
        tata: "Ștefan",
        mama: "Nadia",
        data: "30 Iulie 2022",
        data_confirmare: "10 iulie 2022",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "stefanrosca1990@gmail.com", 
       tel: "+39 388 936 8227",
       phone: "tel:+393889368227",
       viber: "viber://chat?number=%2B393889368227",
       whatsapp: "https://wa.me/+393889368227",
       messenger: "https://www.messenger.com/t/stefan.rosca.5",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Nașterea Măicii Domnului din Padova",
            data: "30 iulie 2022, sâmbătă, ora 16:00",
            adress: "Via Santi Fabiano e Sebastiano, 134, 35143 Padova PD Italia",
            harta: "https://goo.gl/maps/xTDbi2eFjhDoAfW49",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Imperial",
            data: "30 iulie 2022, sâmbătă, ora 17:30",
            adress: "Visco N.5 Limena 35010 Limena, Veneto, Italy",
            harta: "https://goo.gl/maps/aSjXpH9xyWj5RqAm6",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;